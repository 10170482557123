:root {
  --text-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --primary-bg-color: #282c34;
  --secondary-bg-color: #575969;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

p {
  line-height: 1.2em;
}

* {
  box-sizing: border-box;
}

.App {
  font-family: var(--text-font);
  text-align: center;
  background-color: var(--primary-bg-color);
  color: #fff;
  height: 100%;
  overflow: auto;
}

header {
  display: flex;
  align-items: center;
  background: #353441;
  border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
  padding: 4px 0 4px 12px;
  position: sticky;
  top: 0;
  z-index: 1;
}

header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}

.menu button {
  background: none;
  border: none;
}

.navMenu {
  background-color: #4c4f5f;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  transition: transform 0.2s ease-in;
  transform: translate(-100%, 0);
}

.navMenu.open {
  transform: translate(0);
}

.navItems {
  padding: 8px;
  background-color: #212122;
  width: 100%;
  max-width: 340px;
}

.navPromptWrapper {
  border-bottom: 1px solid #ffffff33;
  padding: 10px;
  height: calc(100vh - 55px);
  overflow-y: auto;
}

.navPrompt {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;
}

.navPrompt a {
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 20px;
  text-decoration: none;
}

.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #ececf1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
}

.navPrompt span {
  
  opacity: 0;
}

#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
  top: -45px;
}

pre {
  font-family: var(--text-font);
}

@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navCloseIcon {
  margin: 10px;
}

nav svg {
  float: left;
}

.sideMenu {
  width: 244px;
  padding: 8px;
  background-color: #202022;
}

.sideMenuButton {
  border: 1px solid hsl(0deg 0% 100% / 20%);
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}

.navPrompt:hover,
.sideMenuButton:hover {
  cursor: pointer;
}

.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}

.chatBox {
  background-color: #353540;
  position: relative;
  line-height: 24px;
  color: #d1d5db;
  font-size: 16px;
}

#introsection::before,
#introsection::after {
  content: "";
  float: left;
  color: rgb(0, 134, 244);
}

#introsection {
  text-align: left;
  padding: 20px;
}

#introsection h1 {
  padding-top: 20px;
  line-height: 33px;
}

#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}

#introsection ul {
  list-style-type: square;
}

#introsection pre {
  margin: 0;
  display: inline;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPromptWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.userSVG {
  transform: scale(0.6);
}

.botMessageMainContainer {
  width: 100%;
  background-color: #444654;
  position: relative;
}

.botMessageWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.stop-messgage {
  position: absolute;
  bottom: 10px;
  right: 100px;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10%;
  border: 1px solid rgb(86, 88, 105);
  background-color: rgb(52, 53, 65);
  color: rgb(217, 217, 217);
  cursor: pointer;
}

.stop-messgage:hover {
  background-color: rgb(64, 65, 79);
  /* border: none; */
}

.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}

#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.inputPromptWrapper {
  position: sticky;
  background-color: var(--primary-bg-color);
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  bottom: 0;
  justify-content: center;
  padding: 12px 12px 12px 70px;
}

.inputPromptWrapper div {
  max-width: 800px;
  display: flex;
  margin: 0 auto;
  position: relative;
}

.inputPrompttTextarea {
  padding: 14px;
  flex: 1;
  resize: none;
  background-color: #41414e;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  overflow-y: hidden;
}

form button {
  background: rgb(65, 65, 78);
  border: none;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 43px;
  height: 43px;
}

@media (hover: hover) {
  button:hover {
    cursor: pointer;
    border-radius: 5%;
  }
}

.loginContainer {
  background: var(--primary-bg-color);
  min-height: 100%;
  padding: clamp(15px, 5%, 50px);
  font-family: var(--text-font);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.loginContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

#svgLogo {
  width: 100%;
  max-width: 516px;
  height: auto;
  padding-bottom: 60px;
}

.loginContainer h1 {
  font-size: 30px;
  margin: 0 0 30px 0;
}

.loginContainer p {
  font-size: 18px;
  text-align: justify;
  text-justify: inter-word;
}

.loginDescription {
  padding: 0 clamp(20px, 25%, 50px);
}

.loginButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  margin-top: 20px;
}

button.buttonImage {
  padding: 10px 25px;
  border-radius: 7px;
  color: #fff;
  background: #76ffd8;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

button.buttonImage:hover {
  background-color: rgb(26, 127, 100);
}

button.buttonImage img {
  max-width: 100%;
  height: auto;
}

@media screen and (min-width: 768px) {
  .menu {
  }
  .navMenu.open {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  nav {
    display: flex;
  }
  .sideMenu {
    display: none;
  }
  .chatPromptWrapper {
    padding: 12px;
  }
  .botMessageWrapper {
    padding: 12px;
  }
  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 16px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
  .inputPromptWrapper {
    padding: 12px;
  }
}

@media screen and (min-width: 1536px) {
  .loginContainer h1 {
    font-size: 40px;
  }
  .loginContainer p {
    font-size: 25px;
  }
  button.buttonImage {
    font-size: 22px;
  }
}
